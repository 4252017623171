import React, { useState, useEffect } from 'react';
import '../styles/Projects.css';

const Projects = ({ projectsSectionRef }) => {
  const [isHovered, setIsHovered] = useState({});
  const [isTouched, setIsTouched] = useState(false);

  const handleMouseEnter = (index) => {
    setIsHovered((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setIsHovered((prev) => ({ ...prev, [index]: false }));
  };

  const handleTouchStart = (index) => {
    setIsTouched((prev) => {
      if (prev === index) {
        window.location.href = projects[index].link;
        return false;
      } else {
        return index;
      }
    });
  };

  const handleDocumentClick = (event) => {
    if (!event.target.closest('.card')) {
      setIsTouched(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const projects = [
    {
      link: 'https://www.clockinsmart.com',
      imgSrc: '/ClockIn-64.png',
      title: 'ClockIn Smart',
      description: 'Designed to track and manage work hours, specifically for casual workers'
    },
    {
      link: 'https://github.com/JulioMontesinos',
      imgSrc: '/github_logo.png',
      title: 'My GitHub',
      description: 'A collection of my software development projects'
    }
  ];

  return (
    <section ref={projectsSectionRef} id="projects" className="projects">
      <h1>Projects</h1>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div
            className={`card ${isHovered[index] || isTouched === index ? 'hover' : ''}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            onTouchStart={() => handleTouchStart(index)}
            key={index}
          >
            <div className="face face1">
              <div className="content">
                <a href={project.link} onClick={(e) => isTouched === index && e.preventDefault()}>
                  <img src={project.imgSrc} alt={project.title} width="64" height="64" />
                  <h3>{project.title}</h3>
                </a>
                <p className="card-description">{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;