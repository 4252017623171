import React, {useEffect, useRef} from 'react';
import "../styles/About.css"

const ProfileSection = ({ profileSectionRef }) => {

    const article = useRef();

    useEffect(() => {
        
        const CONFIG = {
            proximity: 43,
            spread: 61,
            blur: 10,
            gap: 28,
            vertical: false,
            opacity: 0,
        };

        const isTouchEvent = (event) => {
          return event && (event.type.startsWith('touch') || event.touches);
        };

        const UPDATE = (event) => {
          
          if (isTouchEvent(event)) {
            return;
          }
            
            const ARTICLE_BOUNDS = article.current.getBoundingClientRect();
        
            if (
                event?.x > ARTICLE_BOUNDS.left - CONFIG.proximity &&
                event?.x < ARTICLE_BOUNDS.left + ARTICLE_BOUNDS.width + CONFIG.proximity &&
                event?.y > ARTICLE_BOUNDS.top - CONFIG.proximity &&
                event?.y < ARTICLE_BOUNDS.top + ARTICLE_BOUNDS.height + CONFIG.proximity
            ) {
              article.current.style.setProperty('--active', 1);
            } else {
              article.current.style.setProperty('--active', CONFIG.opacity);
            }
        
            const ARTICLE_CENTER = [
              ARTICLE_BOUNDS.left + ARTICLE_BOUNDS.width * 0.5,
              ARTICLE_BOUNDS.top + ARTICLE_BOUNDS.height * 0.5,
            ];
        
            let ANGLE = (Math.atan2(event?.y - ARTICLE_CENTER[1], event?.x - ARTICLE_CENTER[0]) * 180) / Math.PI;
            ANGLE = ANGLE < 0 ? ANGLE + 360 : ANGLE;
        
            article.current.style.setProperty('--start', ANGLE + 90);
            
        };

        const isTouchScreen = window.matchMedia('(hover: none) and (pointer: coarse)').matches;
        
        if (!isTouchScreen) {
          document.body.addEventListener('pointermove', UPDATE);
          UPDATE();
        }
        
        return () => {
            if (!isTouchScreen) {
              document.body.removeEventListener('pointermove', UPDATE);
            }
        };
    }, []); 

  return (
    <section ref={profileSectionRef} id="about" className="section">
      <div className="container">
        <article ref={article}>
          <div className="glows"></div>
          <div id="contentAboutMe">
            <div className="column" id="leftColumn">
              <div id="leftTopRow">
                <div className="profileImageContainer">
                  <img src="/MyPhoto.png" alt="Tu imagen" className="profileImage" />
                </div>
              </div>
              <div id="leftBottomRow">
              <h2>Contact Information</h2>
              <div className="contact-details">
                <p>Julio Montesinos Alvarado</p>
                <p>Alicante, Spain</p>
                <p>+34 633 689 976</p>
                <p>juliomontesinosalvarado@gmail.com</p>
              </div>
              <a href="/CV_Julio_Montesinos.pdf" download="CV_Julio_Montesinos.pdf">
                <button className="download-button">Download Resume</button>
              </a>
            </div>

            </div>
            <div className="column" id="rightColumn">
              <h1>About me</h1>
              <p>Hi, I'm Julio, a web developer passionate about technology. I graduated in Computer Engineering from Miguel Hernández University, and since then, I've been exploring and working with various technologies to create dynamic and functional web applications.</p>
              <p>I'm proficient in languages like HTML, CSS, JavaScript, React, and PHP, which I use to build both front-end and full-stack projects. I've also developed Android applications in Java and had the opportunity to work with artificial intelligence models during a research internship, giving me a strong foundation in data handling and algorithms.</p>
              <p>In a previous role, I worked on improving functionalities in C++ for a 3D modeling program, which allowed me to acquire a solid technical foundation. This experience, along with my passion for web development, led me to create ClockIn Smart, a platform designed to help casual workers easily track their hours worked.</p>
              <p>After spending some time in Australia, where I enhanced my English communication, soft skills, and knowledge of web frameworks, I'm ready to progress professionally alongside a new team. During this time, I also worked as a freelance developer for a client, which further improved my skills and adaptability. I'm a problem-solver, always looking to grow and contribute my best to every project.</p>
              <p>Whether it's front-end or full-stack development, I'm excited to bring my skills, creativity, and dedication to a new team. 🚀</p>
          </div>
          </div>
        </article>
      </div>
    </section>

  );
}

export default ProfileSection;