import React, { useRef, useEffect } from 'react';
import '../styles/CareerPath.css';
import Timeline from '../functionality/timeline';

const CareerPath = ({ scrollPos, homeRef, profileSectionRef, careerPathSectionRef, projectsSectionRef }) => {

  return (
    <section id="careerPath" ref={careerPathSectionRef} className="section">
      <h1>Professional and Academic Journey</h1>
      <Timeline
        careerPathSectionRef={careerPathSectionRef}
        homeRef={homeRef}
        profileSectionRef={profileSectionRef}
        projectsSectionRef={projectsSectionRef}
        scrollPos={scrollPos}
      />
    </section>
  );
}

export default CareerPath;