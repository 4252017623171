import React from 'react';
import { Spring, config } from 'react-spring';
import '../styles/Abilities.css';

const Bar = ({ value, label, isVisible }) => {

  return (
    <div className="bar-container">
      <Spring
        to={{ height: isVisible ? `${value * 20}%` : '0%' }}
        config={config.molasses}
      >
        {(props) => isVisible && (
          <>
            <div className="bar" style={props}>
              <div className="bar-value">{value}</div>
            </div>
            <span className="ability">{label}</span>
          </>
        )}
      </Spring>
    </div>
  );
};

export default Bar;
