import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';

const Navbar = ( {homeRef, profileSectionRef, projectsSectionRef, careerPathSectionRef, abilityRef}) => {
  const [isInFirstSection, setIsInFirstSection] = useState(true);
  const sectionsRefs = {
    home: homeRef,
    about: profileSectionRef,
    projects: projectsSectionRef,
    careerPath: careerPathSectionRef,
    abilities: abilityRef,
  };

  const smoothScroll = (event, targetId, speed) => {
    event.preventDefault();
    const targetRef = sectionsRefs[targetId];
    const targetElement = targetRef.current;

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', speed: speed });
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sectionRefs = Object.values(sectionsRefs);

    sectionRefs.forEach((sectionRef, index) => {

      const sectionOffset = sectionRef.current.offsetTop;

      const lengthMax = sectionRefs.length-1;
      const nextSectionOffset = index < lengthMax ? sectionRefs[index + 1].current.offsetTop : Infinity;

      if (scrollPosition >= sectionOffset && scrollPosition < nextSectionOffset) {
        if(index >= 1)
            setIsInFirstSection(false);
          else
            setIsInFirstSection(true);
      }
    });
  };  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isInFirstSection]); 

  return (
    <nav className={isInFirstSection ? 'nav-dark' : 'nav-light'}>
      <ul>
        <li>
          <a href="#home" className="nav-link" onClick={(e) => smoothScroll(e, 'home', 500)} ref={sectionsRefs['home']}>
            Home
          </a>
        </li>
        <li>
          <a href="#about" className="nav-link" onClick={(e) => smoothScroll(e, 'about', 800)} ref={sectionsRefs['about']}>
            About
          </a>
        </li>
        <li>
          <a href="#projects" className="nav-link" onClick={(e) => smoothScroll(e, 'projects', 1000)} ref={sectionsRefs['projects']}>
            Projects
          </a>
        </li>
        <li>
          <a href="#careerPath" className="nav-link" onClick={(e) => smoothScroll(e, 'careerPath', 1200)} ref={sectionsRefs['careerPath']}>
            Career Path
          </a>
        </li>
        <li>
          <a href="#abilities" className="nav-link" onClick={(e) => smoothScroll(e, 'abilities', 1700)} ref={sectionsRefs['abilities']}>
            Abilities
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar; 