import React, { useEffect } from 'react';
import 'particles.js';
import { particlesConfig } from '../functionality/particlesConfig';
import '../styles/Home.css'; 

const Home = ({ homeRef, profileSectionRef  }) => {

  const scrollToProfileSection = () => {
    profileSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    window.particlesJS('home', particlesConfig);
  }, []);

  return (
    <section ref={homeRef} id="home" className="section home-container">
      <div className="text-container">
        <h1 id="myName">I'm Julio Montesinos Alvarado.</h1>
        <h3>Creating digital solutions with passion and creativity.</h3>
      </div>

      <div id="mouse-scroll" onClick={scrollToProfileSection}>
        <div className="mouse">
          <div className="mouse-in"></div>
        </div>
        <div>
          <span className="arrow-1"></span>
          <span className="arrow-2"></span>
          <span className="arrow-3"></span>
        </div>
	    </div>
    </section>
  );
};

export default Home;