import React, { useState, useRef, useEffect } from 'react';
import Bar from '../functionality/Bar'; 
import '../styles/Abilities.css';

const AbilitySection = ( {abilityRef} ) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (abilityRef.current) {
        const rect = abilityRef.current.getBoundingClientRect();
        setIsVisible(rect.top <= window.innerHeight-200 && rect.bottom >= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="abilities" className="section" ref={abilityRef}>
      <h1>Abilities</h1>
      <div className="bar-graph bar-graph-vertical">
        <div className="bar-one bar-container">
          <Bar value={5} label="HTML" isVisible={isVisible} />
        </div>
        <div className="bar-two bar-container">
          <Bar value={4} label="MongoDB" isVisible={isVisible} />
        </div>
        <div className="bar-three bar-container">
          <Bar value={5} label="JavaScript" isVisible={isVisible} />
        </div>
        <div className="bar-four bar-container">
          <Bar value={4} label="React" isVisible={isVisible}/>
        </div>
        <div className="bar-five bar-container">
          <Bar value={3} label="Express.js" isVisible={isVisible} />
        </div>
        <div className="bar-six bar-container">
          <Bar value={4} label="Node.js" isVisible={isVisible}/>
        </div>
        <div className="bar-seven bar-container">
          <Bar value={5} label="Resilient" isVisible={isVisible} />
        </div>
        <div className="bar-eight bar-container">
          <Bar value={4} label="APIs" isVisible={isVisible} />
        </div>
        <div className="bar-nine bar-container">
          <Bar value={3} label="Git" isVisible={isVisible} />
        </div>
        <div className="bar-ten bar-container">
          <Bar value={5} label="Teamwork" isVisible={isVisible} />
        </div>   
      </div>
      <div className="explanation">
        <div className="explanation-container">
          <h2>Graph Explanation</h2>
          <p>
            Above, you will find a visual representation of my technical and non-technical skills. I use a scale from 1 to 5 to showcase my level of expertise in each area. Explore more about my progress and competencies subjectively through this graph.
          </p>
          <ul>
            <li>1 - Novice</li>
            <li>2 - Basic</li>
            <li>3 - Intermediate</li>
            <li>4 - Skilled</li>
            <li>5 - Proficient</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AbilitySection;